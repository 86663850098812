export default [
{
    posicion: 1,
    nombre: "identificacion oficial,identificacion,ine,ife",
    tag: "identificacion,identificacion_oficial"
},
{
    posicion: 2,
    nombre: "curp",
    tag: "curp"
},
{
    posicion: 3,
    nombre: "comprobante domicilio,comprobante de domicilio,comprobante de dom,domicilio",
    tag: "comprobante_domicilio"
},
{
    posicion: 4,
    nombre: "ingresos",
    tag: "comprobante_ingresos"
},
{
    posicion: 5,
    nombre: "constancia",
    tag: "situacion_fiscal"
},
{
    posicion: 6,
    nombre: "predial",
    tag: "predial"
},
{
    posicion: 7,
    nombre: "agua.,_Agua",
    "filtros": "aguascalientes",
    tag: "boleta_agua"
},
{
    posicion: 8,
    nombre: "titulo de propiedad,dictamen de propiedad,propiedad",
    tag: "titulo_propiedad"
},
{
    posicion: 9,
    nombre: "solicitud",
    tag: "solicitud_credito"
},
{
    posicion: 10,
    nombre: "buro",
    tag: "buro_credito"
},
{
    posicion: 11,
    nombre: "matrimonio",
    tag: "acta_matrimonio"
}]