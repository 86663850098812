<template>
	<div>
		<div class="row">
			<div class="col-sm-3 offset-sm-6">
				<div class="input-group mb-3">
				  <input v-model="buscar" type="text" class="form-control" placeholder="Dato a buscar...">
				  <div class="input-group-append">
				    <button class="btn btn-primary">Buscar</button>
				  </div>
				</div>
			</div>
			<div class="col-sm-3">
				<div class="input-group mb-3">
				  <div class="input-group-prepend">
				    <span class="input-group-text" id="basic-addon3">Mostrar</span>
				  </div>
				  <select v-model="ver_todos" name="ver_documentos" id="ver_documentos" class="form-control">
				  	<option value="1">Todos los documentos</option>
				  	<option value="2">Solo los 11 requeridos</option>
				  </select>
				</div>
			</div>	
		</div>	
		<div class="row">
			<div v-for="documento in documentos" class="col-sm-3 mb-3">
				<div class="card">
				  <div class="card-body d-flex flex-column">
				    <h5 class="card-title">{{ documento.tag ? documento.tag : documento.nombre }}</h5>
				    <p class="card-text">{{ documento.descripcion ? documento.descripcion : 'Sin descripción' }}</p>
				    <p class="card-text">Posicion: {{ documento.posicion }}</p>
				    <p class="text-right mt-auto"><button class="btn btn-primary" @click="descargar_documento(documento)">Descargar</button></p>
				  </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import apiSolicitudes from '@/apps/solicitudes/api/solicitudes';
	import orden_docs from '@/apps/solicitudes/conf/orden_docs';

	export default {
		props: {
			solicitud: {
				type:Object,
				default() {
					return {
						documentos: []
					}
				}
			}
		}
		,data() {
			return {
				buscar: null,
				ver_todos: 1,
				// documentos: []
			}
		}
		,mounted() {
			// this.ordenar_docs();
		}
		,methods: {
			async descargar_documento(documento) {
        try {
            let res = (await apiSolicitudes.obtener_documento(this.solicitud.id, documento.id)).data;

            let mime = {pdf: 'application/pdf', jpg: 'image/jpeg', jpeg: 'image/jpeg', png: 'image/png', bmp: 'image/bmp', gif: 'image/gif'};
            let extension = documento.extension.replace('.','');
            this.$helper.descargar_documento(res,documento.nombre);
            // this.url_file = window.URL.createObjectURL(new Blob([res], {type: mime[extension]}));
            // this.modal_ver_doc = true;
        }catch(e) {
            this.$log.info('error',e);
            this.$helper.showMessage('Error','Error al descargar documento: '+e.message,'error','alert');
            // this.$helper.showAxiosError(e,'Error');
        }
      },
      ordenar_docs(pos) {
      	if (!pos)
      		pos = 1;

      	this.documentos = [];
      }
		}
		,computed: {
			documentos() {
				let docs_org = this.$helper.clone(this.solicitud.documentos);
				let posicion_fake = 12;

				if (this.buscar) {
					let por_buqueda = [];

					for(let i=0; i<docs_org.length; i++) {
						if (docs_org[i].nombre.indexOf(this.buscar) != -1) {
							por_buqueda.push(docs_org[i]);
							continue;
						}

						if (docs_org[i].tag && docs_org[i].tag.indexOf(this.buscar) != -1) {
							por_buqueda.push(docs_org[i]);
							continue;
						}
					}

					docs_org = por_buqueda;
				}

				this.$log.info('orden_docs', orden_docs);
      	for(let i=0; i<docs_org.length; i++) {
      		for(let a=0; a<orden_docs.length; a++) {
      			this.$log.info('tag original', docs_org[i].tag);
      			let tags = orden_docs[a].tag.split(',');

      			for(let b=0; b<tags.length; b++) {
      				this.$log.info('tag a buscar', tags[b]);
	      			if (tags[b] == docs_org[i].tag) {
	      				this.$log.info('se encontró por tag');
	      				docs_org[i].posicion = orden_docs[a].posicion;
	      				break;
	      			}
	      		}

      			if (!docs_org[i].posicion) {
      				let nombres = orden_docs[a].nombre.split(',');

      				let nombre_normalizado = this.$helper.normalizar(docs_org[i].nombre);
      				this.$log.info('nombre: ',nombre_normalizado);
      				for(let b=0; b<nombres.length; b++) {
      					let nombre_buscar_normalizado = this.$helper.normalizar(nombres[b]);
      					this.$log.info('a buscar', nombre_buscar_normalizado);
      					if(!docs_org[i].posicion && nombre_normalizado.indexOf(nombre_buscar_normalizado) != -1) {
      						this.$log.info('se encontró por nombre');
      						docs_org[i].posicion = orden_docs[a].posicion;
      						break;
      					}
      				}
      			}
      		}

      		if (!docs_org[i].posicion) {
      			docs_org[i].posicion = posicion_fake;
      			posicion_fake++;
      		}
      	}

      	if (this.ver_todos == 2) {
	      	let filtrados = [];

	      	for(let i=0; i<docs_org.length; i++) {
	      		if (docs_org[i].posicion < 12)
	      			filtrados.push(docs_org[i]);
	      	}

	      	docs_org = filtrados;
	      }

				docs_org.sort((a,b) => a.posicion - b.posicion);

				return docs_org;

			}
		}
		// ,watch: {
		// 	solicitud: {
		// 		handler() {
		// 			this.ordenar_docs();
		// 		}
		// 		,deep: true
		// 	}
		// }
	}
</script>

<style lang="scss">
	.card {
		height: 100%
	}
</style>