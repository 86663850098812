<template>
    <div>
        <Modal :options="{width: '90vw', close: true}" @close="$emit('close')">
            <div class="title">Solicitud {{ solicitud.id }}</div>
            <div class="body">
                <div class="row">
                    <div class="col-sm-3">
                        <fieldset>
                            <legend>Información del crédito</legend>
                        
                            <ul class="list-info">
                                <li class="text-truncate"><i class="fa-solid fa-user"></i> <span :title="solicitud.nombre">{{ solicitud.nombre }}</span></li>
                                <li class="text-truncate"><i class="fa-solid fa-envelope"></i> <span :title="solicitud.email">{{ solicitud.email }}</span></li>
                                <li><i class="fa-solid fa-calendar-days"></i> Alta: <span title="Fecha de creación">{{ solicitud.created_at }}</span></li>
                                <li><i class="fa-solid fa-calendar-days"></i> Últ modificación: <span title="Fecha de última modificación">{{ solicitud.updated_at }}</span></li>
                                <li><i class="fa-solid fa-question"></i> Medio: <span title="Medio de información">{{ solicitud.medio_informacion.nombre }}</span></li>
                                <li><i class="fa-solid fa-money-bill-1-wave"></i> Monto solicitado: <span title="Monto solicitado">${{ $helper.moneyFormat(solicitud.importe_solicitado) }}</span></li>
                                <li><i class="fa-solid fa-check"></i> Monto pre-autorizado: <span title="Monto pre-autorizado">${{ $helper.moneyFormat(solicitud.importe_pre_solicitado) }}</span></li>
                                <li><i class="fa-solid fa-image"></i> Modelo imágenes: <span title="Modelo imágenes"></span></li>
                            </ul>
                        </fieldset>
                    </div>
                    <div class="col-sm-9">
                        <div class="row mb-3">
                            <div class="col-sm-12">
                                <ul class="nav nav-tabs">
                                    <li class="nav-item">
                                        <a :class="clase('cliente')" @click.prevent="tab_activo='cliente'">Cliente</a>
                                    </li>
                                    <li class="nav-item">
                                        <a :class="clase('garantias')" @click.prevent="tab_activo='garantias'">Garantias</a>
                                    </li>
                                    <li class="nav-item">
                                        <a :class="clase('estatus')" @click.prevent="tab_activo='estatus'">Estatus areas</a>
                                    </li>
                                    <li class="nav-item">
                                        <a :class="clase('historial')" @click.prevent="tab_activo='historial'">Historial</a>
                                    </li>
                                    <li class="nav-item">
                                        <a :class="clase('documentos')" @click.prevent="tab_activo='documentos'">Documentos</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <Cliente v-if="tab_activo == 'cliente'" :solicitud="solicitud" />
                                <Garantia v-if="tab_activo == 'garantias'" :solicitud="solicitud" />
                                <EstatusArea v-if="tab_activo == 'estatus'" :solicitud="solicitud" @update="obtener_solicitud" />
                                <Historial v-if="tab_activo == 'historial'" :solicitud="solicitud" />
                                <Documento v-if="tab_activo == 'documentos'" :solicitud="solicitud" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal'

import apiSolicitudes from '@/apps/solicitudes/api/solicitudes'

import Cliente from './Solicitud/Cliente'
import Garantia from './Solicitud/Garantia'
import EstatusArea from './Solicitud/EstatusArea'
import Historial from './Solicitud/Historial'
import Documento from './Solicitud/Documento'

export default {
    components: {
        Modal, Cliente, Garantia, EstatusArea, Historial, Documento
    }
    ,props: {
        sol: {
            type: Object
            ,default() {
                return {
                    id: 0
                    ,scoring: {
                        buro: []
                        ,avatar: null
                        ,ocr: []
                    }
                    ,leadscore: []
                    ,medio_informacion: {
                        nombre: null
                    }
                }
            }
        }
    }
    ,data() {
        return {
            solicitud: {
                id: 0
                ,scoring: {
                    buro: []
                    ,avatar: null
                    ,ocr: []
                }
                ,leadscore: []
                ,logs: []
                ,observaciones: []
                ,garantias_originacion: []
                ,medio_informacion: {
                    nombre: null
                }
            }
            ,tab_activo: 'cliente'
        }
    }
    ,mounted() {
        this.obtener_solicitud();
    }
    ,methods: {
        async obtener_solicitud() {
            try {
                this.solicitud = (await apiSolicitudes.obtener_solicitud(this.sol.id)).data;
            }catch(e) {
                this.$log.info('error',e);
                this.$helper.showAxiosError(e,'Error');
            }
        }
        ,clase(tipo) {
            return 'nav-link'+(tipo == this.tab_activo ? ' active' : '');
        }
    }
    ,computed: {
        alianza() {
            return this.$auth.can('creditos','alianza_soc') ? 'SOC' : 'FRD';
        }
    }
}
</script>