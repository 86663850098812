<template>
    <div>
        <div v-for="log in solicitud.logs" class="historial-step">
            <div class="historial-time">
                Publicado hace {{ diferencia(log.created_at) }}

                <div class="historial-icon">
                    <i class="fa-solid fa-user-tie"></i>
                </div>
            </div>
            <div class="historial-log">
                <div class="historial-log-title">
                    {{ log.titulo }}
                </div>
                <div class="historial-log-content">
                    {{ log.descripcion }}
                </div>
                <div class="historial-log-info">
                    <div class="info-usuario">{{ log.user && log.user.name ? log.user.name : 'Sistema' }}</div>
                    <div class="info-email">{{ log.user && log.user.email ? log.user.email : 'n/a' }}</div>
                    <div class="info-fecha">{{ log.created_at }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        solicitud: {
            type: Object,
            default() {
                return {
                    
                }
            }
        }
    }
    ,methods: {
        diferencia(fecha_log) {
            let ahora = this.$moment().unix();
            let fecha = this.$moment(fecha_log).unix();
            let diferencia = ahora-fecha;

            let tiempo = ''

            if (diferencia >= 31104000) {
                let anios = Math.floor(diferencia / 31104000);
                tiempo += anios+' año(s)';
                diferencia -= anios;
            }else if (diferencia >= 2592000) {
                let meses = Math.floor(diferencia / 2592000)
                tiempo += meses+' mes(es)';
                diferencia -= meses;
            }else if (diferencia >= 86400) {
                let dias = Math.floor(diferencia / 86400);
                tiempo += dias+' dia(s)';
                diferencia -= dias;
            }else if (diferencia >= 3600) {
                let horas = Math.floor(diferencia / 3600);
                tiempo += horas+' hora(s)';
                diferencia -= horas;
            }else if (diferencia > 60) {
                let minutos = Math.floor(diferencia / 60);
                tiempo += minutos+' minuto(s)';
            }else {
                tiempo += diferencia+' segundos(s)';
            }
            
            return tiempo;
        }
    }
}
</script>

<style lang="scss">
    .historial-step {
        width: 100%;
        min-height: 120px;
        display: flex;

        .historial-time {
            width: 180px;
            min-height: 100%;
            font-size: 0.8em;
            padding: 10px 40px;
            text-align: center;
            border-right: solid 1px grey;
            // flex-grow: 1;
            position: relative;

            .historial-icon {
                width: 50px;
                background-color: rgb(212, 216, 236);
                font-size: 2.4em;
                color: rgb(67, 88, 184);
                border-radius: 30px;
                position: absolute;
                top: 10px;
                right: -25px;
            }
        }

        .historial-log {
            max-width: 80%;
            min-height: 100%;
            padding-left: 40px;
            padding-bottom: 40px;
            // flex-grow: 1;

            .historial-log-title {
                font-size: 1.3em;
                font-weight: bold;
                color: rgb(83, 92, 223);
                margin-bottom: 20px;
            }

            .historial-log-content {
                max-width: 100%;
                word-break: break-word;
            }

            .historial-log-info {
                margin-top: 10px;
                display: flex;

                .info-usuario, .info-email, .info-fecha {
                    background-color: rgb(212, 212, 212);
                    padding: 5px 10px;
                    margin-right: 10px;
                    border-radius: 5px;
                    flex-flow: column;
                }
            }
        }
    }
</style>